<template>
  <v-container fluid>
    <div>
      <h1 class="text-h4">{{ $lang.header.template }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row v-if="err || success" dense no-gutters class="px-0">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="pb-4">
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.exampleVariables }}
            </v-tab>
            <v-tab
              :key="3"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="8"
                      class="d-flex"
                    >
                      <v-text-field
                        v-model="template.name"
                        outlined
                        dense
                        :label="$lang.labels.name"
                        required
                        :rules="[v => !!v || 'Required!']"
                        :persistent-hint="isEdit"
                        :hint="formatHint"
                        :readonly="!userCanEdit || template.isSystem"
                        data-cy="template-name"
                        class="required-asterisk"
                      ></v-text-field>
                      <v-btn
                        icon
                        color="primary"
                        class="ml-2"
                        style="margin-top: 2px"
                        title="copy"
                        @click="copyField()"
                      >
                        <v-icon>mdi-clipboard-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="4" class="pl-2">
                      <v-select
                        v-model="template.engine"
                        :items="enginesFormatted"
                        :label="$lang.labels.engine"
                        required
                        :rules="[v => !!v || 'Required!']"
                        outlined
                        hide-details
                        dense
                        class="required-asterisk"
                        data-cy="template-engine"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters class="pb-4">
                    <v-col
                      cols="12"
                      style="position: relative"
                    >
                      <div class="d-flex justify-end">
                        <v-row wrap no-gutters class="pt-1 align-center" style="max-width: 100%">
                          <v-col cols="4">
                            <h3>{{ $lang.labels.body }}</h3>
                          </v-col>
                          <v-col cols="8" class="justify-end">
                            <v-row class="d-flex justify-end align-center">
                              <v-col cols="2"></v-col>
                              <v-col cols="5" class="d-flex align-center">
                                <p class="mr-3 pt-2">Add:</p>
                                <v-select
                                  :disabled="!template.engine"
                                  :items="variables"
                                  :label="$lang.labels.variables"
                                  outlined
                                  hide-details
                                  dense
                                  data-cy="variables"
                                  item-text="key"
                                  item-value="key"
                                  @change="addShortCode"
                                ></v-select>
                              </v-col>
                              <v-col cols="5">
                                <div class="">
                                  <toolbar-search :search-field="'getTemplates'" @addTemplate="addTemplateToBody"></toolbar-search>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                      <prism-editor
                        v-model="template.body"
                        class="my-editor"
                        :highlight="highlighter"
                        line-numbers
                        data-cy="template-body"
                        @blur="handleBlur"
                        @focus="cursorPosition = ''"
                      ></prism-editor>
                      <v-btn
                        style="position: absolute; top: 82px; right: 24px"
                        :disabled="!template.engine"
                        color="accent"
                        class="ml-2"
                        icon
                        small
                        data-cy="template-help"
                        @click="templateHelpShow = true"
                      >
                        <v-icon small>mdi-help</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex">
                        <v-btn
                          color="secondary"
                          class="button-default-width"
                          data-cy="template-back"
                          :to="{ name: 'templates', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="template.name"
                          :title="$lang.actions.delete"
                          type="template"
                          :is-disabled="!userCanDelete || template.isSystem"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'template-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteTemplateFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'templates' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-flex justify-end">
                        <div class="mr-5">
                          <v-btn
                            icon
                            color="primary ms-1"
                            class="mr-1"
                            style="margin-top: 2px"
                            title="copy"
                            :disabled="!copyPaste"
                            @click="copyFields()"
                          >
                            <v-icon>mdi-clipboard-outline</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            color="primary"
                            style="margin-top: 2px"
                            title="paste"
                            :disabled="!copyPaste"
                            @click="pasteFields()"
                          >
                            <v-icon>mdi-clipboard</v-icon>
                          </v-btn>
                        </div>
                        <div>
                          <v-btn
                            :disabled="!valid || lock || !userCanEdit"
                            color="accent"
                            class="button-default-width color-black"
                            data-cy="template-submit"
                            @click="generatePdf()"
                          >
                            <v-icon
                              left
                              dark
                              class="mr-1"
                            >
                              mdi mdi-package-up
                            </v-icon>
                            {{ $lang.actions.pdf }}
                          </v-btn>
                          <v-btn
                            :disabled="!valid || lock || !userCanEdit || !template.body"
                            color="success"
                            class="button-default-width ml-2"
                            data-cy="template-submit"
                            @click="submit()"
                          >
                            <v-icon
                              left
                              dark
                              class="mr-1"
                            >
                              mdi mdi-floppy
                            </v-icon>
                            {{ $lang.actions.submit }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row no-gutters wrap class="pt-4">
                  <div class="d-inline-flex">
                    <h3>{{ $lang.routes.translations }}</h3>
                    <v-btn
                      color="accent"
                      class="ml-2"
                      icon
                      :disabled="!isEdit"
                      data-cy="template-translation-add"
                      @click="translations.push({ language: '', name: '', text: '', isEdit: false })"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <v-col
                    cols="12"
                  >
                    <template v-for="(trans, i) in translations">
                      <v-row :key="i" no-gutters wrap class="pt-2">
                        <v-col
                          cols="2"
                          class="pr-1"
                        >
                          <v-select
                            v-model="trans.language"
                            :items="availableLanguages"
                            :label="$lang.labels.language"
                            outlined
                            dense
                            required
                            :readonly="!userCanEdit"
                            :data-cy="`template-translation-language-${i}`"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="7"
                        >
                          <v-text-field
                            v-model="trans.name"
                            outlined
                            dense
                            :label="$lang.labels.name"
                            required
                            :rules="[v => !!v || 'Required!', (v) => $options.filters.javaVariableConventionRules(v, false) || $lang.errors.variableJavaWrong]"
                            :readonly="!userCanEdit"
                            :data-cy="`template-translation-name-${i}`"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          class="pl-1 text-right"
                        >
                          <div class="d-inline-flex">
                            <v-btn
                              color="primary"
                              :disabled="!trans.language || !trans.name || !trans.text"
                              :data-cy="`template-translation-save-${i}`"
                              @click="submitTranslation(trans, i)"
                            >
                              <v-icon
                                left
                                dark
                                class="mr-1"
                              >
                                mdi mdi-content-save-all
                              </v-icon>
                              {{ trans.isEdit ? $lang.actions.update : $lang.actions.save }}
                            </v-btn>
                            <v-btn
                              color="error"
                              class="ml-2"
                              :data-cy="`template-translation-delete-${i}`"
                              @click="confirmDeletion(trans, i)"
                            >
                              <v-icon
                                left
                                dark
                                class="mr-1"
                              >
                                mdi mdi-delete-variant
                              </v-icon>
                              {{ $lang.actions.delete }}
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-textarea
                            v-model="trans.text"
                            outlined
                            dense
                            rows="4"
                            auto-grow
                            :label="$lang.labels.body"
                            required
                            :rules="[v => !!v || 'Required!']"
                            :readonly="!userCanEdit"
                            :data-cy="`template-translation-text-${i}`"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <key-val :data="template.exampleVariables" :can-edit="userCanEdit" @dataChanged="template.exampleVariables = $event" />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="3"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreTemplate($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
        <yes-no :title="$lang.actions.delete" :action-text="$lang.header.sureDeleteTranslation" @submit="deleteTranslation" @closeDialog="tempItem = null; tempIndex = null; yesNoShow = false"></yes-no>
      </v-dialog>
      <v-dialog v-if="templateHelpShow" v-model="templateHelpShow" max-width="30%">
        <template-help :engine="template.engine" @closeDialog="templateHelpShow = false"></template-help>
      </v-dialog>
      <v-snackbar
        v-model="snackShow"
        :color="snackColor"
        content-class="text-center"
        top
      >
        <span class="color-accent-text">{{ snackbarText }}</span>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import Table from '@/components/ui/tables/HistoryDatatable'
import {
  createPdfUsingPOST as createPdf,
  createTextTemplateTranslationUsingPOST as createTranslation,
  createTextTemplateUsingPOST as createTemplate,
  deleteTextTemplateTranslationUsingDELETE as deleteTranslation,
  getHistoriesUsingGET as getHistories,
  getRolesUsingGET as getRoles,
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplateTranslationsUsingGET as getTranslations,
  updateTextTemplateTranslationUsingPUT as updateTranslation,
  updateTextTemplateUsingPUT as updateTemplate,
  deleteTextTemplateUsingDELETE as deleteTemplate
} from '@/utils/api'
import YesNo from '@/components/ui/modals/YesNo'
import TemplateHelp from '@/components/ui/modals/TemplateHelp'
import KeyVal from './KeyValPairModal'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ToolbarSearch from '@/components/toolbar/ToolbarSearch'

// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import copy from 'copy-to-clipboard'

export default {
  components: {
    YesNo,
    TemplateHelp,
    Table,
    PrismEditor,
    KeyVal,
    UserRolesSelect,
    ToolbarSearch,
    ActionButtonWithConfirmation
  },
  data() {
    return {
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      copyPaste: false,
      tab: 0,
      yesNoShow: false,
      templateHelpShow: false,
      err: '',
      success: '',
      valid: false,
      isEdit: false,
      loading: true,
      lock: false,
      template: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        status: '',
        body: '',
        roles: [],
        isSystem: false,
        engine: '',
        exampleVariables: null
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
      translations: [],
      tempItem: null,
      tempIndex: null,
      history: { items: [], meta: {} },
      isSuperUser: false,
      engines: ['HANDLEBARS', 'MUSTACHE', 'THYMELEAF', 'VELOCITY'].sort(),
      cursorPosition: '',
      variables: [],
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false
    }
  },
  computed: {
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    },
    enginesFormatted() {
      return this.engines.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.template.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.template.modifiedOn)}, ID: ${this.template.id}`
    }
  },
  watch: {
    'template.engine': {
      handler(newVal, oldVal) {
        if (['HANDLEBARS', 'MUSTACHE'].includes(oldVal) && !['HANDLEBARS', 'MUSTACHE'].includes(newVal)) {
          const rg = /[\\{{]{2}([a-zA-Z0-9]{1,})[\\}}]{2}/gm

          this.replaceShortcodes(rg, 2, 2)
        } else if (oldVal === 'THYMELEAF') {
          const rg = /[\\$]{1}[\\{]{1}([a-zA-Z0-9]{1,})[\\}]{1}/gm

          this.replaceShortcodes(rg, 2, 1)
        } else if (oldVal === 'VELOCITY') {
          const rg = /[\\$]{1}([a-zA-Z0-9]{1,})/gm

          this.replaceShortcodes(rg, 1, 0)
        }
      }
    },
    'template.exampleVariables': {
      handler() {
        this.generateVariableOptions()
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'templateEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'TEMPLATE_DELETER') || this.isSuperUser
    }

    if ('showCopyPaste' in localStorage) {
      this.copyPaste = JSON.parse(localStorage.showCopyPaste)
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getTemplate({ templateId: this.$route.params.id })
            .then((res) => {
              this.template = res.data.data

              this.generateVariableOptions()

              if (this.template.roles) {
                this.editRolesIds = this.template.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
                this.useRolesIds = this.template.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
                this.viewRolesIds = this.template.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

                this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
              } else {
                this.userCanEdit = true
              }

              this.loading = false

              getTranslations({ templateId: this.$route.params.id })
                .then((res) => {
                  this.translations = res.data.data.items.map((x) => {
                    x.isEdit = true

                    return x
                  })
                })
                .catch((err) => {
                  this.err = err
                })

            })
            .catch((err) => {
              this.err = err
            })
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            const tempCopy = JSON.parse(this.$route.params.copy)

            this.template = tempCopy.tempItem
            this.translations = tempCopy.tempTranslations
            this.generateVariableOptions()
          }
          this.loading = false
        }
      })
  },
  methods: {
    copyField() {
      if (this.template.name) {
        copy(this.template.name)
        this.showSnack(this.$lang.success.copiedClipboard, 'success')
      } else {
        this.showSnack(this.$lang.errors.nothingToCopy, 'warning')
      }
    },
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    deleteTemplateFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteTemplate({ templateId: this.$route.params.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    addTemplateToBody(val) {
      const position = this.cursorPosition === 0 || this.cursorPosition ? this.cursorPosition : this.template.body.length

      this.template.body = this.template.body.slice(0, position) + '<flowy-template>' + val + '</flowy-template>' + this.template.body.slice(position)
    },
    copyFields() {
      localStorage.setItem('copiedTemplateData', this.template.body)
      localStorage.setItem('copiedTemplateTranslation', JSON.stringify(this.translations))
    },
    pasteFields() {
      this.template.body = localStorage.getItem('copiedTemplateData')
      this.translations = JSON.parse(localStorage.getItem('copiedTemplateTranslation'))
    },
    highlighter(code) {
      return highlight(code, languages.js) //returns html
    },
    fetchHistory(options) {
      if (!this.template.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.template.id
      obj.resourceType = 'TEXT_TEMPLATE'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.err = err
        })
    },
    restoreTemplate(data) {
      this.template = data

      if (this.template && this.template.roles) {
        this.editRolesIds = this.template.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.template.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.template.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }

      getTranslations({ templateId: this.$route.params.id })
        .then((res) => {
          this.translations = res.data.data.items.map((x) => {
            x.isEdit = true

            return x
          })
        })
        .catch((err) => {
          this.err = err
        })
    },
    confirmDeletion(item, index) {
      this.tempIndex = index
      this.tempItem = item
      this.yesNoShow = true
    },
    async deleteTranslation() {

      if (!this.tempItem.isEdit) {
        this.translations.splice(this.tempIndex, 1)
        this.tempIndex = null
        this.tempItem = null
        this.yesNoShow = false

        return
      }

      this.lock = true
      this.yesNoShow = false

      const { id } = this.template

      let res = null

      try {

        res = await deleteTranslation({ templateId: id, translationId: this.tempItem.id })

        if (res && res.status !== 200) {
          this.err = this.$lang.errors.translationDelete
          setTimeout(() => this.err = '', 5000)
          this.lock = false
          this.tempIndex = null
          this.tempItem = null

          return
        }
        this.success = this.$lang.success.translationDeleted

        setTimeout(() => this.success = '', 5000)

        this.lock = false

        this.translations.splice(this.tempIndex, 1)

        this.tempIndex = null
        this.tempItem = null

      } catch (err) {
        this.err = err
        this.lock = false
        this.tempIndex = null
        this.tempItem = null
        setTimeout(() => this.err = '', 5000)
      }
    },
    async submitTranslation(item, index) {
      this.lock = true

      let res = null

      const { id } = this.template

      const payload = {
        language: item.language,
        name: item.name,
        text: item.text
      }

      try {

        res = item.isEdit ? await updateTranslation({ templateId: id, translationId: item.id, request: payload }) : await createTranslation({ templateId: id, request: payload })

        if (res && res.status !== 200) {
          this.err = item.isEdit ? this.$lang.errors.translationUpdate : this.$lang.errors.translationCreate
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = item.isEdit ? this.$lang.success.translationUpdated : this.$lang.success.translationCreated

        setTimeout(() => this.success = '', 5000)

        this.lock = false

        item.isEdit = true

        this.translations[index] = { ...res.data.data, isEdit: true }

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    download(content, fileName) {
      const file = new Blob([content],
        {
          type: 'application/pdf'
        })

      const reader = new FileReader()

      reader.onload = function()
      {
        const popup = window.open()

        const link = document.createElement('a')

        link.setAttribute('href', reader.result)
        link.setAttribute('download', fileName)
        popup.document.body.appendChild(link)
        link.click()
      }
      reader.readAsDataURL(file)
    },
    async generatePdf() {
      this.err = ''
      this.loading = true

      const { engine, body, exampleVariables } = this.template

      let res = null

      try {

        res = await createPdf({ request: { templateBody: body, type: engine, variables: exampleVariables }, $config: { responseType: 'blob' } })

        if (res && res.status !== 200) {
          this.err = this.$lang.errors.pdfGenerate
          setTimeout(() => this.err = '', 5000)
          this.loading = false

          return
        }

        this.loading = false

        this.download(res.data, `${this.template.name}.pdf`)

      } catch (err) {
        this.err = err
        this.loading = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async submit() {
      this.err = ''

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.err = this.$lang.errors.editRoleCreate
        setTimeout(() => this.err = '', 5000)
      }

      this.lock = true

      let res = null

      const { id } = this.template

      if (!this.isEdit) {
        delete this.template.id
      }

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.template.roles = tempRoles

      try {

        res = this.isEdit ? await updateTemplate({ templateId: id, request: this.template }) : await createTemplate({ request: this.template })

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.templateUpdate : this.$lang.errors.templateCreate)
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.templateUpdated : this.$lang.success.templateCreated

        if (!this.isEdit) {
          this.$router.push({
            name: 'templateEdit',
            params: {
              id: res.data.data.id,
              message: this.success
            }
          })
        }

        this.lock = false

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    addShortCode(event) {
      const position = this.cursorPosition === 0 || this.cursorPosition ? this.cursorPosition : this.template.body.length
      let value = ''

      if (['HANDLEBARS', 'MUSTACHE'].includes(this.template.engine)) {
        value = '{{' + event + '}}'
      } else if (this.template.engine === 'THYMELEAF') {
        value = '${' + event + '}'
      } else if (this.template.engine === 'VELOCITY') {
        value = '$' + event
      }

      this.template.body = this.template.body.slice(0, position) + value + this.template.body.slice(position)
    },
    handleBlur(event) {
      this.cursorPosition = event.target.selectionStart
    },
    replaceShortcodes(regexp, start, end) {
      let { body } = this.template

      if (['HANDLEBARS', 'MUSTACHE'].includes(this.template.engine)) {
        const vars = body.match(regexp)

        vars.map((item) => {
          body = body.replaceAll(item, '{{' + item.slice(start, item.length - end) + '}}')

          return item
        })
        this.template.body = body
      } else if (this.template.engine === 'THYMELEAF') {

        const vars = body.match(regexp)

        vars.map((item) => {
          body = body.replaceAll(item, '${' + item.slice(start, item.length - end) + '}')

          return item
        })
        this.template.body = body
      } else if (this.template.engine === 'VELOCITY') {

        const vars = body.match(regexp)

        vars.map((item) => {
          body = body.replaceAll(item, '$' + item.slice(start, item.length - end))

          return item
        })
        this.template.body = body
      }
    },
    generateVariableOptions() {
      if (this.template.exampleVariables) {
        this.variables = Object.entries(this.template.exampleVariables).reduce((o, a) => {
          o.push({
            key: a[0].slice(2),
            value: a[1]
          })

          return o
        }, [])
      } else {
        this.variables = []
      }
    }
  }
}
</script>
<style lang="scss" scoped>

@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 55vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
